<template>
  <div class="product-details-wrapper">
    <div
      class="no-article"
      v-if="isFindProduct && !product.title"
    >
      <div>未找到该产品</div>
    </div>
    <div
      v-else
    >
      <div class="product-details">
        <div
          v-if="isFindProduct && product.title"
        >
          <div
            v-show="showType === 'img'"
            class="img-swiper"
          >
          <swiper
              class="product-details-components-swiper"
              :options="swiperOptions"  
            >
              <template
                v-for="(item, index) in list"
              >
                <swiper-slide
                  :key="index"
                  class="product-details-slide"
                > 
                  <div
                    class="product-details-slide-img"
                  >
                    <img
                      :src="item"
                    />
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
          <div
            v-if="showType !== 'img'"
            class="product-details-video-wrpapper"
          >
            <video class="product-details-video" controls autoplay x5-playsinline playsinline webkit-playsinline  x-webkit-airplay="true">
              <source :src="video" type="video/mp4" />
            </video>
          </div>
          <div class="change-type">
            <div
              @click="showType = 'img'"
              :class="showType === 'img' ? 'active' : ''"
            >
              {{ $t('image') }}
            </div>
            <div
              v-if="product.video"
              @click="showType = 'video'"
              :class="showType !== 'img' ? 'active' : ''"
            >
              {{ $t('video') }}
            </div>
          </div>
        </div>
        <div class="product-details-content">
          <div>{{ product.title }}</div>
          <div class="product-details-information-content-w-e"  v-html="product[propKeys[navActive]]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { showMessage, goGet } from '@/utils'
export default {
  name: 'ProductDetails',
  metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 10,
        slidesPerView: 'auto',
      },
      isFindProduct: false,
      product: {},
      showType: 'video',
      imgActive: 0,
      navActive: 0,
      propKeys: ['parameters', 'control', 'diagram', 'circuitDiagram', 'picture_album']
    }
  },

  computed: {
    list() {
      return this.product.cover.split(',').map(list => process.env.VUE_APP_API + list)
    },
    video() {
      return process.env.VUE_APP_API + this.product.video
    }
  },

  mounted() {
    const { id } = this.$route.params
    id ? this.productDetails(id) : showMessage('未找到对应产品', 'error')
  },
  methods: {
    productDetails(id) {
      goGet('/ewinlight/product/find', {id: id}).then(res => {
        this.isFindProduct = true
        if (res.code === 200) {
          this.product = res.data
          this.showType = this.product.video ? 'video' : 'img'
          const reg = new RegExp('https://nestjs.mxliu.top/api','g')
          this.product.parameters = res.data.parameters.replace(reg, process.env.VUE_APP_API)
        }
      })
    },
    download() {
      this.product.picture_album ? window.open(process.env.VUE_APP_API + this.product.picture_album) : showMessage(this.$t('nofile'), 'error')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-details-wrapper {
  .product-details {
    .img-swiper {
      display: flex;
      width: 100%;
      margin: 0 auto;
      height: 210px;
      .product-details-components-swiper {
        .product-details-slide-img {
          width: 100%;
          height: 210px;
          display: flex;
          justify-content: center;
          img {
            object-fit: cover;
          }
        }
      }
    }
    .product-details-video-wrpapper {
      width: 100%;
      overflow: hidden;
      height: 210px;
      .product-details-video {
        width: 100%;
        height: 210px;
      }
    }
    .change-type {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 18px;
      &>div {
        width: 75px;
        height: 28px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background: #fff;
        color: #333;
        &.active{
          color: #FFFFFF;
          background: #333333;
        }
      }
    }
    &-content {
      width: 100%;
      margin-top: 20px;
      padding: 14px;
      &>div{
        &:nth-child(1) {
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          line-height: 28px;
          text-align: center;
        }
      }
      ::v-deep .product-details-information-content-w-e {
        margin-top: 30px;
         * {
           font-size: 14px !important;
           line-height: 24px;
           color: #666666;
         }
         img {
           max-width: 100%;
         }
         p {
           margin: 10px 0;
         }
      }
    }
  }
}
</style>